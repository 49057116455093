import { render, staticRenderFns } from "./HotlineInfo.vue?vue&type=template&id=3b6f4f13&scoped=true&"
import script from "./HotlineInfo.vue?vue&type=script&lang=js&"
export * from "./HotlineInfo.vue?vue&type=script&lang=js&"
import style0 from "./HotlineInfo.vue?vue&type=style&index=0&id=3b6f4f13&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6f4f13",
  null
  
)

export default component.exports